import { IOT, SFR, SPC, KANBAN, PORTAL, REPORT, NOTIFY, ERP, PROGRAM, TOOLING, VSM, SCM, WMS, SD } from './icons.js';

export const appShowOrder = [ 'iot', 'sfr', 'spc', 'dashboard', 'portal', 'report', 'notify', 'erp', 'program', 'tooling', 'vsm', 'scm', 'wms', 'sd' ] ;

const appUrlSet = {
  iot:        'https://dashboard.dotzero.com',
  sfr:        'https://sfr.dotzero.com',
  spc:        'https://spc.dotzero.com',
  dashboard:  'https://dashboard.dotzero.com',
  portal:     'https://console.dotzero.com',
  report:     'https://report.dotzero.com',
  notify:     'https://notify.dotzero.com',
  erp:        'https://erp-tool.dotzero.com',
  program:    'https://program-management.dotzero.com',
  tooling:    'https://tooling.dotzero.com',
  vsm:        'https://vsm.dotzero.com',
  scm:        'https://scm.dotzero.com',
  wms:        'https://wms.dotzero.com',
  sd:         'https://scm-app-service-01-dotzero.azurewebsites.net',
};

const appIconSet = {
  iot:        IOT,
  sfr:        SFR,
  spc:        SPC,
  dashboard:  KANBAN,
  portal:     PORTAL,
  report:     REPORT,
  notify:     NOTIFY,
  erp:        ERP,
  program:    PROGRAM,
  tooling:    TOOLING,
  vsm:        VSM,
  scm:        SCM,
  wms:        WMS,
  sd:         SD,
};

export const getAppSet = (userAppPermissions) => {
  let appPermissions = JSON.parse(JSON.stringify(userAppPermissions));
  if( process.env.REACT_APP_URL_SET_FROM_ENV === 'true' ) {
    try {
      let appsEnv = JSON.parse(process.env.REACT_APP_URL_SET);
      for( const app of Object.keys(appPermissions) ) {
        if( !appsEnv.hasOwnProperty(app) ) {
          appPermissions[app] = false;
        }
      }
    } catch (e) {
      // parse app_url_set error
      // set userAppPermissions to all false
      for( const key of Object.keys(appPermissions) ) {
        appPermissions[key] = false;
      }
    }
  }

  return appPermissions;
};

export const getAppName = (locale, app) => {
  let keyMap = require(`./i18n/${locale}/appTitle.js`).default;
  let appName = keyMap[app];

  if( process.env.REACT_APP_NAME_SET_FROM_ENV === 'true' ) {
    try {
      keyMap = JSON.parse(process.env.REACT_APP_NAME_SET);
      if( keyMap.hasOwnProperty(app) ) {
        appName = keyMap[app];
      }
    } catch (e) {
      keyMap = {};
    }
  }

  return appName;
};

export const getAppUrl = (app) => {
  let keyMap = JSON.parse(JSON.stringify(appUrlSet));

  if( process.env.REACT_APP_URL_SET_FROM_ENV === 'true' ) {
    try {
      keyMap = JSON.parse(process.env.REACT_APP_URL_SET);
    } catch (e) {
      keyMap = {};
    }
  }

  return keyMap.hasOwnProperty(app) ? keyMap[app] : '';
};

export const getAppIconByName = (app) => {
  return appIconSet.hasOwnProperty(app) ? appIconSet[app] : null;
};

export const getAppIconByUrl = (appUrl) => {
  if( appUrl.includes('console') || appUrl.includes('portal') ) {
    return appIconSet["portal"];
  }

  if( appUrl.includes('sfr') ) {
    return appIconSet["sfr"];
  }

  if( appUrl.includes("spc") ) {
    return appIconSet["spc"];
  }

  if( appUrl.includes("vsm") ) {
    return appIconSet["vsm"];
  }

  if( appUrl.includes("dashboard") ) {
    return appIconSet["dashboard"];
  }

  if( appUrl.includes("notify") ) {
    return appIconSet["notify"];
  }

  if( appUrl.includes("wip") ) {
    return appIconSet["wip"];
  }

  if( appUrl.includes("ems") ) {
    return appIconSet["ems"];
  }

  if( appUrl.includes("tooling") ) {
    return appIconSet["tooling"];
  }

  if( appUrl.includes("report") ) {
    return appIconSet["report"];
  }

  if( appUrl.includes("erp") ) {
    return appIconSet["erp"];
  }

  if( appUrl.includes("program") ) {
    return appIconSet["program"];
  }

  if( appUrl.includes("sd") ) {
    return appIconSet["sd"];
  }

  if( appUrl.includes("scm") ) {
    return appIconSet["scm"];
  }

  return null;
};


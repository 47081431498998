const obj = {
  iot:        '設備監控管理系統',
  dashboard:  '看板管理',
  portal:     '系統組織管理',
  sfr:        '精實製程管理系統',
  vsm:        '價值流程系統',
  spc:        '製程管制系統',
  notify:     '推播通知系統',
  tooling:    '刀具與模具管理系統',
  report:     '報表系統',
  erp:        'ERP 同步系統',
  sd:         '訂單系統',
  program:    'CNC 程式傳輸管理系統',
  wms:        '倉儲管理系統',
  scm:        '供應鏈管理系統',
  qrcodeInstallation: 'QR Code 裝機流程',
  dzDocs:     'Dot Zero API 文件',
};

export default obj;


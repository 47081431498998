import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import { appShowOrder, getAppSet, getAppIconByName, getAppName, getAppUrl } from './utils.js'
import messages from './i18n/i18n.js';
import './App.css';

const userUrl = process.env.REACT_APP_USER_URL.endsWith("/") ? process.env.REACT_APP_USER_URL : `${process.env.REACT_APP_USER_URL}/`;
const ssoUrl  = process.env.REACT_APP_SSO_URL.endsWith("/") ? process.env.REACT_APP_SSO_URL : `${process.env.REACT_APP_SSO_URL}/`;

function App() {
  const [ language, setLanguage ] = useState(getDefaultLanguage());
  const [ loadOk, setLoadOk ] = useState(false);
  const userPermission = useRef({});
  const user = useRef(null);

  useEffect( () => {
    if( window.cookieStore !== undefined ) {
      window.cookieStore.addEventListener('change', (e) => {
        let lang = Cookies.get("dzLang");
        if( lang !== language && messages.hasOwnProperty(lang) === true ) {
          setLanguage(lang);
        }
      });
    }
  }, [ language ]);

  useEffect( () => {
    const token = Cookies.get("token");
    fetch(`${userUrl}dzService/based`, {
      method: 'GET',
      headers: new Headers({ 'Authorization': `Bearer ${token}` })
    }).then((dzServiceRes) => {
      if( dzServiceRes.ok ) {
        return dzServiceRes.json();
      }

      throw new Error('Error.');
    }).then((dzServices) => {
      dzServices.forEach((service) => {
        if( service.is_web === true ) {
          userPermission.current[service.service_code] = false;
        }
      });

      userPermission.current["iot"] = false;

      fetch(`${userUrl}permission/app`, {
        method: 'GET',
        headers: new Headers({ 'Authorization': `Bearer ${token}` })
      }).then((res) => {
        if( res.ok ) {
          return res.json();
        }

        throw new Error('Error.');
      }).then((res) => {
        for( const [ key, value ] of Object.entries(res.apps) ) {
          if( userPermission.current.hasOwnProperty(key) ) {
            userPermission.current[key] = value;
          }
        }

        userPermission.current["iot"] = userPermission.current["dashboard"];

        // setTimeout for redirect to sso
        const tokenDecoded = jwtDecode(token);
        const exp = dayjs.unix(tokenDecoded.exp);
        const expMinusTenMinutes = exp.subtract(10, 'minute');

        const tenantId = JSON.parse(Cookies.get("user"))['tenantId'];
        const redirectUri = `${ssoUrl}login?redirectUri=${window.location.origin}&tenantId=${tenantId}`;

        if( expMinusTenMinutes.isBefore(dayjs()) ) {
          // the expire time is shorter than 10 minutes, redirect to sso to exchange new cookies
          window.location.replace(redirectUri);
        } else {
          // setTimeout for exchange token
          setTimeout(() => {
            window.location.replace(redirectUri);
          }, expMinusTenMinutes.diff(dayjs()));
        }

        // set user info
        user.current = JSON.parse(Cookies.get("user"));
      }).catch((err) => {
        user.current = null;
        userPermission.current = {};
      }).then(() => {
        setLoadOk(true);
      });
    }).catch((err) => {
      user.current = null;
      userPermission.current = {};
      setLoadOk(true);
    });
  }, [])

  const getContent = () => {
    // if set env to limit what frontend can be used
    // this function will consider the env and set userPermission based on it
    const newUserPermission = getAppSet(userPermission.current);

    let content = [];
    for( const app of appShowOrder ) {
      const valid = newUserPermission[app];
      if( valid ) {
        content.push(
          <div className="app-container non-select" key={ app } >
            <div className="app-icon-container">
              <a href={ getAppUrl(app) }>
                <img
                  src={ getAppIconByName(app) }
                  alt={ getAppName(language, app) }
                  className="app-icon"
                  onClick={() => { window.top.location.href = getAppUrl(app); }}
                />
              </a>
            </div>
            <div className="app-text-container">
              <div className="app-text">
                <span className="app-text-span"> { getAppName(language, app) } </span>
              </div>
            </div>
          </div>
        );
      } else {
        content.push(
          <div className="app-container non-select not-valid" key={ app } >
            <div className="app-icon-container">
              <div className="app-text-no-permission"> { app === 'dashboard' ? 'kanban' : app } </div>
            </div>
            <div className="app-text-container">
              <div className="app-text">
                <span className="app-text-span"> { getAppName(language, app) } </span>
              </div>
            </div>
          </div>
        );
      }
    }

    // account
    if( user.current !== null && user.current.hasOwnProperty("name") ) {
      content.push(
        <div className="app-container non-select" key="user" >
          <div className="app-icon-container">
            <a href={ `${process.env.REACT_APP_SSO_URL}userInfo` } style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className="user-logo" key="user-logo" onClick={() => { window.top.location.href = `${process.env.REACT_APP_SSO_URL}userInfo`; }}>
                { user.current.name.charAt(0) }
              </div>
            </a>
          </div>
          <div className="app-text-container">
            <div className="app-text">
              <span className="app-text-span"> { messages[language]['personalInformation'] } </span>
            </div>
          </div>
        </div>
      );
    }

    return content;
  };

  return (
    loadOk
      ?
        <div className="app-set">
          { getContent() }
        </div>
      :
        null
  );
}

const getDefaultLanguage = () => {
  let lang = Cookies.get("dzLang");
  if( lang === null || lang === undefined ) {
    lang = window.navigator.language === 'zh-TW' ? 'zh-TW' : 'en-US';
  }

  // check if system support this lang
  if( messages.hasOwnProperty(lang) === false ) {
    lang = window.navigator.language === 'zh-TW' ? 'zh-TW' : 'en-US';
  }

  return lang;
};

export default App;

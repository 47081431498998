import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route path='/' component={ App } />
      <Redirect from='/*' to='/' />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);


const obj = {
  iot:        'IoT',
  dashboard:  'DZ Dashboard',
  portal:     'Management Portal',
  sfr:        'Shop Floor Reporting',
  vsm:        'VSD / VSM',
  spc:        'Statistical Process Control',
  notify:     'Notification',
  tooling:    'Tooling Management System',
  report:     'Custom Report',
  erp:        'ERP Tool',
  sd:         'Sales Distribution',
  program:    'Program Management',
  wms:        'Warehouse Management System',
  scm:        'Supply Chain Management',
  qrcodeInstallation: 'QR Code Installation',
  dzDocs:     'Dot Zero APIs',
};

export default obj;

